<template>
  <div class="content-profile">
    <b-row class="sub-content" no-gutters>
      <b-col cols="4" style="min-height: 80px">
        <div class="my-auto">
          <div class="profile-img"></div>
        </div>
      </b-col>
      <!-- <c-btn >share</c-btn> -->
      <b-col cols="8">
        <div class="width-sub-content">
          <div class="f-12">Member Level</div>
          <div>
            <span class="f-14 text-bold">Firstname Lastname </span>
          </div>
          <div class="f-12">(Member ID.)</div>
          <div class="qr-icon">
            <font-awesome-icon icon="qrcode" class="" />
          </div>
        </div>
      </b-col>
    </b-row>

    <!-- <div class="manage-icon">
      <v-menu location="bottom">
        <template v-slot:activator="{ props }">
          <c-btn flat :ripple="false" type="icon" v-bind="props" size="x-small">
            <font-awesome-icon
              :icon="['fas', 'ellipsis-v']"
              class="icon-edit-profile"
            />
          </c-btn>
        </template>

        <v-list style="min-width: 175px">
          <v-list-item h('/manage')" link-class="px-3">
            <div class="content-between">
              <span>แก้ไข</span>
              <font-awesome-icon
                :icon="['fas', 'pencil-alt']"
                class="icon-edit-profile f-md"
              />
            </div>
          </v-list-item>
          <template v-if="displayLogoutButton">
            <v-list-item 'logout')" link-class="px-3">
              <div class="content-between">
                <span>ออกจากระบบ</span>
                <font-awesome-icon
                  :icon="['fas', 'sign-out-alt']"
                  class="icon-edit-profile f-md"
                />
              </div>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>
    </div> -->
    <div class="content-menu-list px-3 py-2">
      <b-row>
        <b-col
          cols="12"
          class="point-panel pb-0 text-center"
          style="border-right: unset"
        >
          <div class="f-md text-gray w-100">
            <div>คะแนนสะสม</div>
          </div>

          <div class="d-flex align-items-center justify-content-center w-100">
            <!-- <span class="fa-stack fa-1x icon-background mr-2"> -->
            <!-- <c-img
                :src="$store.state.domain + $store.state.theme.point_logo"
                class="img-point"
                v-if="$store.state.theme.point_logo"
              ></c-img> -->

            <font-awesome-icon icon="star" class="point-icon-color mr-2" />
            <!-- </span> -->

            <span class="f-xl f-bold"> x,xxx </span>
          </div>

          <div class="page-nav">
            <font-awesome-icon
              :icon="['fas', 'chevron-right']"
              class="cursor-pointer"
            />
          </div>
        </b-col>
        <b-col cols="12" class="py-0">
          <!-- <v-divider class="border-opacity-75"></v-divider> -->
          <hr />
        </b-col>
        <b-col cols="6" class="pt-0">
          <div class="f-md">
            <div class="text-gray f-sm">คะแนนที่กำลังหมดอายุ</div>
            <!--   -->
            <div>
              <span class="f-bold">xxxx</span>
              คะแนน (xxxx)
            </div>
            <!-- <div v-else>-</div> -->
          </div>
        </b-col>
        <b-col cols="6" class="pt-0">
          <div class="content-between justify-content-end">
            <div class="f-sm text-nowrap">
              <span class="text-gray"> ช่องทางการสะสมคะแนน:</span>

              <u class="highlight-color f-sm cursor-pointer text-black">
                <font-awesome-icon :icon="['fas', 'plus']" size="xs" />เพิ่ม
              </u>
            </div>
          </div>
          <div class="marketplace-panel justify-content-end">
            <!-- <template
              v-for="(market_list, index) of marketPlaceObj.marketplace"
            >
              <div
                class="icon-marketplace"
                v-if="index < 4"
                :key="
                  market_list.shopName +
                  market_list.branchId +
                  '' +
                  market_list.id
                "
              >
                <img
                  :src="
                    market_list.marketplaceUrl ||
                    'https://api-hourglass-admin-dev.dosetech.co/media/NewsImage/231026021009526.jpg'
                  "
                  alt="Marketplace icon"
                  :class="[market_list.status ? '' : 'op-low']"
                />
              </div>
            </template> -->
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="my-2">
      <div class="content-between mb-2">
        <div class="f-lg color-primary f-bold">Mission</div>

        <router-link to="/mission" class="color-primary">
          <u>ทั้งหมด</u>
        </router-link>
      </div>
      <b-row>
        <b-col><b-card></b-card></b-col> <b-col><b-card></b-card></b-col>
      </b-row>
    </div>
    <b-card> </b-card>
    <div class="content-between mb-2">
      <div class="f-lg color-primary f-bold">ข่าวสาร</div>

      <router-link to="/mission" class="color-primary">
        <u>ทั้งหมด</u>
      </router-link>
    </div>
    <b-card> </b-card>
    <div class="nav-footer">
      <div>
        <font-awesome-icon :icon="['fas', 'home']" />
      </div>
      <div class="position-relative nav-footer-items">
        <font-awesome-icon :icon="['fas', 'ticket-alt']" />
      </div>
      <div class="nav-footer-items">
        <font-awesome-icon :icon="['fas', 'gift']" />
      </div>
      <div class="nav-footer-items">
        <font-awesome-icon :icon="['fas', 'user']" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  //   props: {
  //     profile: {
  //   },
  data() {
    return {};
    // return {
    //   pictureProfile: "",
    //   displayLogoutButton:
    //     this.$store.state.theme.line_display_logout_button == "1"
    //       ? true
    //       : false,
    //   displayPointAndChannel:
    //     this.$store.state.theme.line_display_point_channel == "1"
    //       ? true
    //       : false,
    //   displayConnectChannel:
    //     this.$store.state.theme.display_connect_channel == "1" ? true : false,
    //   layoutDisplay: this.$store.state.theme.line_layout,
    //   displayMemberLevel:
    //     this.$store.state.theme.line_display_member_level == "1" ? true : false,
    // };
  },

  created() {
    // this.getCompleteProfile();
  },
};
</script>

<style lang="scss" scoped>
.icon-marketplace {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    border-radius: 50%;
  }
  .cover {
    background: #858585;
    opacity: 0.5;
    position: absolute;
    top: 0;
    height: 25px;
    width: 25px;
    left: 0;
    border-radius: 50%;
  }
  border-radius: 50%;
  width: 25px;
  height: 25px;
  /* overflow: hidden; */
  position: relative;
  padding: 1px;
  // margin-top: 2px;
}
.content-profile {
  // margin: 0.5rem;
  padding: 10px;
  // background-color: white;
  // color: black;
  border-radius: 10px;
}

.sub-content {
  padding: 0 10px 10px 10px;
  margin-bottom: 0.25rem;
  position: relative;
}
.icon-edit-profile {
  font-size: var(--text-xl);
  color: #858585;
}

.profile-img {
  position: absolute;
  width: 80px;
  height: 80px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid black;
  border-radius: 50%;
  left: 4px;
  top: 4px;
}
.panel-image {
  width: 100px;
  height: 100px;
  cursor: pointer;
  border: 3px solid #000;
  border-radius: 50%;
  bottom: 0px;
}
.box-point {
  font-size: 30px;
  color: #000;
  font-weight: 600;
}
.position-img {
  position: absolute;
  right: 120px;
  top: 110px;
}
.pd-box-point {
  padding: 45px 0;
}
.qr-custom {
  position: absolute;
  top: 70px;
  margin-left: 50px;
}
.qr-size {
  width: 65%;
}

.icon-color {
  color: #c9c9c9;
}
.icon-color-gray {
  color: #5d5d5d;
}

.width-sub-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  // width: 190px;
}
.icon-level {
  width: 25%;
}
@media only screen and (orientation: portrait) and (min-width: 737px) and (max-width: 1023px) {
  /* For iPad mini , iPad */
  .qr-size {
    width: 30%;
  }
  .qr-custom[data-v-755e242a] {
    margin-left: 30px;
  }
  .icon-level {
    width: 12%;
  }
}
@media only screen and (orientation: portrait) and (min-width: 1024px) and (max-width: 1366px) {
  /* For iPad Pro */
  .qr-size {
    width: 30%;
  }
  .qr-custom[data-v-755e242a] {
    margin-left: 30px;
  }
  .icon-level {
    width: 10%;
  }
}
.point-panel {
  border-right: 1px solid #d5d5d5;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
.b-r {
  border-left: 1px solid #c9c9c9;
}
.marketplace-panel {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 5px;
  justify-content: flex-start;
}
.op-low {
  opacity: 0.15;
}
.progress {
  height: 10px;
  position: relative;
  border: 1px solid #c9c9c9;
  background-color: white;
  overflow: visible;
}

.h-50x {
  height: 50px;
}
::v-deep.tooltip-info .tooltip-inner {
  background: white;
  color: #7a7a7a;
  width: 250px;
  max-width: unset;
  font-size: var(--text-xs);
}
::v-deep.tooltip-info {
  opacity: 1;
}
::v-deep.bs-tooltip-top .arrow::before {
  border-top-color: white;
}

.content-menu-list {
  border: 1px solid rgb(212, 212, 212);
  border-radius: 5px;
  position: relative;
}
.bg-gray {
  background-color: #f1f1f1;
}
.manage-icon {
  position: absolute;
  top: 10px;
  right: 10px;
}
.qr-icon {
  position: absolute;
  bottom: 10px;
  right: 12px;
}
.page-nav {
  position: absolute;
  top: 10px;
  right: 15px;
}
.f-mxs {
  font-size: 8px;
}
.f-xs {
  font-size: 10px;
}
.f-sm {
  font-size: 12px;
}
.f-md {
  font-size: var(--text-md, 14px);
}
.f-lg {
  font-size: var(--text-lg, 16px);
}
.f-xl {
  font-size: var(--text-xl, 20px);
}
.f-2xl {
  font-size: 24px;
}
.profile-img {
  background-image: url(/img/avatars/9.png);
}
.nav-footer {
  background: white;
  color: black;
  font-size: 28px;
  width: 100%;
  display: inline-flex;
  position: absolute;
  bottom: 0;
  height: 60px;
  z-index: 1;
  justify-content: space-around;
  align-items: center;
  box-shadow: -1px 9px 16px 0px rgb(138, 138, 138);
  left: 0;
}
</style>
